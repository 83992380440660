import * as React from 'react';



export default function Header(){
    return (
        <header style={{
            width: '100%',
            backgroundColor: '#28130a',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
        <img src={'/meatball-pics-banner-protoxcf.png'}
            style={{
                border: '2px solid #f1e376',
                borderRadius: '10px',
                marginTop: '100px',
                marginBottom: '300px',
            }}
        />
        </header>
        
    );
}