import React, {useState, useEffect} from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';

export default function ImageList(){
    const [images, setImages] = useState([]);
    const [likedImages, setLikedImages] = useState(new Set());
    const [touchStarted, setTouchStarted] = useState(false);

    useEffect(() => {
        fetchImages();

        const storedLikes = JSON.parse(sessionStorage.getItem('likedImages')) || [];
        setLikedImages(new Set(storedLikes));
    }, []);

    const fetchImages = async () => {
        try{
            const response = await fetch('https://meatball.pics/api/images/getImages');
            
            if(!response.ok){
                throw new Error('bad network response');
            }
            const data = await response.json();

            setImages(data);
        }catch(error){
            console.error('error fetching data', error);
        }
    };

    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    
    const handleTouchStart = (imageId) => {
        setTouchStarted(true);
        handleLike(imageId);
    };

    const handleImageClick = (image) => {
        
        setSelectedImage(image);
        setOpen(true);
        
    }

    const handleClose = () => {
        setOpen(false);
        
    }

    const handleLikeClick = (imageId) => {
        if(!touchStarted){
            handleLike(imageId);
        }
        setTouchStarted(false);
    }

    const handleLike = async(imageId) => {
        const isLiked = likedImages.has(imageId);
        try{
            let url;
            let method = 'POST';
            if(isLiked){
                url = `https://meatball.pics/api/images/unlike?id=${imageId}`;
                
            } else{
                url = `https://meatball.pics/api/images/like?id=${imageId}`;
            }

            const response = await fetch(url, {
                method,
                header: {
                    'Content-Type': 'application/json',
                },
            });

            if(response.ok){
                const data  = await response.json();
                const imageIndex = images.findIndex(image => image.id === imageId);

                if(imageIndex !== -1){
                    
                    const updatedImage = {...images[imageIndex], likes: data.likes};
                    const updatedImages = [...images];
                    updatedImages[imageIndex]  = updatedImage;
                    setImages(updatedImages);

                    const newLikedImages = new Set(likedImages);
                    if(isLiked){
                        newLikedImages.delete(imageId);
                    }else{
                        newLikedImages.add(imageId);
                    }

                    sessionStorage.setItem('likedImages', JSON.stringify(Array.from(newLikedImages)));

                    setLikedImages(newLikedImages);
                }

                

                
            }
        }catch(error){
            console.error("error updating likes.", error);
        }
        
    };

    

    

    

    return (
        <div style={{display: 'flex', justifyContent:'center', backgroundColor: '#28130a'}}>
            <List sx={{width:'90%'}}>
            {images.map((image, index) => (
                <React.Fragment key={image.id}>
                <Card sx={{
                    mb: 15,
                    backgroundColor: '#512614',
                    alignItems: 'center'
                    }}>

                    <CardHeader title={image.title}
                     titleTypographyProps={{color: "#faefd1", sx: {fontSize: '2rem'}}}
                     subheader={new Date(image.createdAt).toLocaleString()}
                     subheaderTypographyProps={{color: "#faefd1", sx: {fontSize: '1rem'}}}
                     
                    />
                    <CardMedia
                        component="img"
                        image={image.url}
                        sx={{
                            width:'50%',
                            height:'50%',
                            border: '1px solid #faefd1',
                            borderRadius: '10px',
                            objectFit:'contain',
                            objectPosition:'center',
                            display: 'flex',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                            }}
                        onClick={() => handleImageClick(image)}    
                    />
                    <CardContent style={{color: '#faefd1', fontSize: '1.5rem'}}>
                            <IconButton onClick={() => handleLikeClick(image.id)}
                                         onTouchStart={() => handleTouchStart(image.id)}>
                                <FavoriteIcon style={{fontSize: '2rem', color: likedImages.has(image.id) ? 'red' : '#faefd1'}}/>
                            </IconButton>
                        {image.likes}
                    </CardContent>
                </Card>
                {index < images.length - 1 && <Divider />}

                </React.Fragment>
            ))}

            </List>
            <Dialog open={open} onClose={handleClose} maxWidth="false">
                <DialogContent style={{padding: 0, height: 'auto', display: 'flex', justifyContent: 'center'}}>
                    <img src={selectedImage?.url} style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                    objectPosition: 'center'
                                                    }}
                                                    
                    />
                </DialogContent>
                <DialogActions sx={{backgroundColor: '#512614'}}>
                    <Button sx={{cursor: 'pointer', color: '#faefd1'}}onClick={handleClose}>close</Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}